<template>
  <div id="divSurveyListWrapper" class="surveys-wrapper">
    <BrandTwinTabs class="surveys-wrapper-tabs" :options="tabOptions"></BrandTwinTabs>
    <div class="list-container">
      <div class="list-container-title">
        <VueText sizeLevel="6" weightLevel="4">ANKETLER</VueText>
      </div>
      <div v-if="surveys" class="list-container-cards">
        <div v-for="s in surveys" class="survey-card" :key="s.surveyId">
          <div class="survey-card-image">
            <BrandMediaContentBox
              :mediaSrc="s.image"
              alt="survey-image"
              :aspectRatios="propData.media.aspect.by_16_9"
            >
            </BrandMediaContentBox>
          </div>
          <div class="survey-card-desc">
            <VueText sizeLevel="3" weightLevel="2" class="survey-card-desc-date">{{
              s.startDate | parseISODate
            }}</VueText>
            <VueText sizeLevel="6" weightLevel="3" class="survey-card-desc-text">{{
              s.surveyName
            }}</VueText>
          </div>
          <router-link
            :to="{
              name: routes.takeSurvey,
              params: { id: s.surveyId },
            }"
            class="survey-card-start"
          >
            <VueText sizeLevel="4" weightLevel="3" color="white-100">ANKETE BAŞLA</VueText>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BrandTwinTabs from '@/components/brand/BrandTwinTabs/BrandTwinTabs';
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import RoutesSecure from '@/router/routes/RoutesSecure.js';
import RoutesSurvey from '@/router/routes/secure/RoutesSurvey.js';
import RoutesRoot from '@/router/routes/RoutesRoot.js';
import VueText from '@/components/shared/VueText/VueText.vue';
import GameApi from '@/services/Api/gamification';
import dateUtils from '@/mixins/dateUtils';
import StorageProps from '@/mixins/storageProps.js';

export default {
  name: 'SurveysHome',
  components: { BrandTwinTabs, VueText, BrandMediaContentBox },
  mixins: [StorageProps, dateUtils],
  data() {
    return {
      surveys: null,
      tabOptions: {
        leftItem: {
          label: 'ANKET',
          route: `${RoutesRoot.Secure.path}/${RoutesSecure.Surveys.path}/${RoutesSurvey.SurveyHome.path}`,
          isActive: true,
        },
        rightItem: {
          label: 'TEST',
          route: `${RoutesRoot.Secure.path}/${RoutesSecure.Surveys.path}/${RoutesSurvey.TestHome.path}`,
          isActive: false,
        },
      },
    };
  },
  computed: {
    routes() {
      return { takeSurvey: RoutesSurvey.TakeSurvey.name };
    },
    propData() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      return { media };
    },
  },
  created() {
    this.setAppBarTitle(`${this.currentUser.name}`);
    GameApi.getSurveys(2).then(res => {
      if (res.data && res.data.Data) {
        const {
          Data: { surveys },
        } = res.data;
        this.surveys = surveys;
      }
    });
  },
  // beforeMount() {
  //   this.setAppBarTitle(`${this.currentUser.name}`);
  // },
  methods: {
    goTo(surveyId) {
      this.$router.push({
        name: `${RoutesSurvey.TakeSurvey.name}`,
        params: { id: surveyId },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.surveys-wrapper {
  &-tabs {
    position: fixed;
    width: 100%;
    height: palette-space-level('75');
    background-color: palette-color-level('grey', '10');
    opacity: 0.99;
    border-bottom: 1px solid palette-color-level('grey', 20);
  }
  .list-container {
    padding: 95px palette-space-level('20') palette-space-level('20') palette-space-level('20');
    &-title {
      margin-bottom: palette-space-level('20');
    }
    &-cards {
      .survey-card {
        margin-bottom: palette-space-level('20');
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        &-start {
          display: flex;
          align-items: center;
          justify-content: center;
          height: palette-space-level('60');
          background-image: linear-gradient(100deg, #602ee5, #3b37de 100%);
          border-bottom-left-radius: palette-space-level(5);
          border-bottom-right-radius: palette-space-level(5);
        }
        &-desc {
          padding: palette-space-level('20');
          &-date {
            margin-bottom: palette-space-level('5');
          }
        }
      }
    }
  }
}
</style>
